import { GA_EVENTS } from "../common/gaEventConstants";
import { DATEFILTER } from "../constants/filter-constants";

const specialCountryCodes = new Set(['id', 'idic']);

const specialBDCountryCodes = new Set(['bd']);

export function isSpecialCountryCode(countryCode) {
    return specialCountryCodes.has(countryCode);
  }

  export function isSpecialBDCountryCodes(countryCode) {
    return specialBDCountryCodes.has(countryCode);
  }
  
  export function countryCheck(countryCode) {
    const countries = ["id", "idic", "pk", "bd"];
    return countries.includes(countryCode);
}

export const isBdApplicable = (countryCode) => {
  return countryCode === "bd";
};

export const countryCodeInCaps = (countryCode) => {
  return countryCode === 'idic'? 'ID' : countryCode.toUpperCase();
};

export const filterColumnsByCountry = (columns, countryCode, reportName) => {
  if (!columns) return [];

  let filteredColumns = columns;

  if (countryCode === "bd") {
    filteredColumns = filteredColumns.filter((col) => col.field !== "confirmedStatus");
    
    if (reportName === "Outlet Master") {
      filteredColumns = filteredColumns.filter((col) => !["isActive", "distributorName", "city", "zip", "state"].includes(col.field));
    }
    
    if (reportName === "Product Master") {
      const fieldsToRemove = ["eanNumber", "itemTypeCode", "numerator", "denominator", "dtCode", "dtSiteCode"];
      filteredColumns = filteredColumns.filter((col) => !fieldsToRemove.includes(col.field));
    }
  }

  return filteredColumns;
};

export const filterTableData = (filterData, countryCode, reportName) => {
  if (!Array.isArray(filterData)) return [];
  if (!countryCode || !reportName) return filterData;

  return filterData.filter((filter) => 
    !(
      (countryCode === "bd" && reportName === "Outlet Master" && filter.field === "isActive") ||
      (countryCode === "bd" && reportName === "Product Master" && filter.field === "dtSiteCode")
    )
  );
};



export const getLabel = (isThailandCond, isBangladeshCond) => {
  if (isThailandCond) {
      return "ECO";
  }
  if (isBangladeshCond) {
      return "Secondary [TP]";
  }
  return "GSV";
}


export function getViewType(isDisciplinary, isDistributor) {
  return isDisciplinary ? "KPI View" : (isDistributor ? "DT View" : "Team View");
}

export function getEcoTargetHeaderName(selectedValue) {
 return selectedValue === "day" ? "New ECO Target" : "ECO Target";
}

export function getEcoAchievedHeaderName(selectedValue) {
  return selectedValue === "day" ? "New ECO Actuals" : "Eco Actuals";
 }

 export function getProdCallTargetHeaderName(selectedCountryCode,newKpiForID) {
 return newKpiForID ? "Bills Productivity Target" : (selectedCountryCode === "vn" || selectedCountryCode === "th") ?  "PC Target" : "PC Target %";
 }

 export function getProdCallAchievedHeaderName(selectedCountryCode,newKpiForID) {
  return newKpiForID ? "Bills Productivity Actuals" : (selectedCountryCode === "vn" || selectedCountryCode === "th") ?  "PC Actuals" : "PC Actuals %";
 }

 export function getAchValue(selectedCountryCode, name) {
  return selectedCountryCode === "th" && name === "Effective Covered Outlets %";
 }

 export function getProductCallCheck(selectedCountryCode) {
  return selectedCountryCode === "vn" || selectedCountryCode === "th";
 }

 export function getShowDtSiteCode(selectedCountryCode) {
  return selectedCountryCode !== "pk" && selectedCountryCode !== "bd" && selectedCountryCode !== "id" && selectedCountryCode !== "idic";
 }

 export const comparatorFn = ({valueA, valueB}) => {
  const emailA = valueA.email.split("@")[0];
  const emailB = valueB.email.split("@")[0];
  if (isNaN(emailA) && isNaN(emailB)) {
    if (emailA === emailB) return 0;
    return emailA > emailB ? 1 : -1;
  } else if (isNaN(emailA)) {
    return 1;
  } else if (isNaN(emailB)) {
    return -1;
  } else {
    return emailA - emailB;
  }
}

export const numbercomparatorFn = ({ valueA, valueB }) => {
  const normalizeValue = (value) => {
    if (value === "NA") return 0;

    if (typeof value === "object" && value !== null) {
      if (value.hasOwnProperty("achievedPercentage")) {
        return Number(value.achievedPercentage) || 0;
      }
      return 0;
    }

    return Number(value) || 0;
  };

  valueA = normalizeValue(valueA);
  valueB = normalizeValue(valueB);

  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
};

export const getEmailState = ({ indexState, level, userDetails }) => {
  const hasLevelEmail = level[indexState - 1]?.email;
  const email = hasLevelEmail ? level[indexState - 1].email : userDetails?.email || "";
  return email;
};

export const getEmail = ({ indexState, emailState, level }) => {
  return emailState && level[indexState - 1]?.role === "LEVEL2"
    ? emailState.split("@")[0]
    : emailState;
};

export const getSiteName = ({ indexState, emailState, level }) => {
  if (emailState && level[indexState - 1]?.role === "LEVEL2") {
    return `Site ${emailState.split("@")[1]}`;
  }
  return emailState && level[indexState - 1]?.role === "LEVEL3" 
    ? "Team Leader" 
    : emailState;
};

export const generateParentLevelRoleObj = ({ indexState, email, siteName, level, userDetails }) => {
  const role = level[indexState - 1]?.role || userDetails?.role || "";
  return {
    level: role,
    name: level[indexState - 1]?.email
      ? level[indexState - 1].email.split("@")[0]
      : "",
    email: email,
    siteName: siteName,
  };
};

export const generateParentLevelNameObj = ({ indexState, emailState, level, userDetails }) => {
  const levelname = level[indexState - 1]?.levelname || userDetails?.role || "";
  return {
    level: levelname,
    name: level[indexState - 1]?.email
      ? level[indexState - 1].email.split("@")[0]
      : "",
    email: emailState,
  };
};

export const getUrlEndpoint = (parentLevel, distributorSitecode, apiParam, siteCodeValue) => {
  return parentLevel?.level === "LEVEL3" && distributorSitecode
    ? `role=${parentLevel.level}&type=${apiParam}&dtCode=${siteCodeValue}`
    : `role=${parentLevel.level}&type=${apiParam}`;
};

export const getDistributorValue = (isDistributor, isDisciplinary) => {
  if (isDistributor) return "individual-distributor-kpis";
  return isDisciplinary ? "individual-disciplinary-kpis" : "individual-performance-kpis";
};

export const getStartDateValue = (selectedValue, todaysDate, startDate, startDatePrev) => {
  if (selectedValue === "day") return todaysDate;
  return selectedValue === "mtd" ? startDate : startDatePrev;
};

export const getEndDateValue = (selectedValue, todaysDate, lastDate, lastDatePrev) => {
  if (selectedValue === "day") return todaysDate;
  return selectedValue === "mtd" ? lastDate : lastDatePrev;
};

export const getColums = (isDisciplinary, disciplinaryColumns, performanceClicked, performanceColumns, assortmentColumns) => {
  if (isDisciplinary)
    return disciplinaryColumns;
  return performanceClicked ? performanceColumns : assortmentColumns;
}

export const getColumnData = ({column, index, columnName}) => {
  if (index === 0) {
    return {
      ...column,
      headerName: columnName,
      pinned: "left",
      cellStyle: { borderRight: "1px solid #CCCFD0" },
    };
  }
  return column;
}

export const isValidFilter= (reportName, filterName) => {
  const validConditions = [
      { report: "Product Master", filters: ["Item Code"] },
      { report: "Salesperson Visit Summary", filters: ["Outlet ID"] },
      { report: "Promotion Status", filters: ["Customer Code"] },
      { report: "Demand Capture Report", filters: ["SKU Id", "Customer Code"] },
      { report: "Order Loss Report", filters: ["SKU Id", "Customer Code"] },
      { report: "Inventory Status", filters: ["Item Code", "Product Name"] }
  ];

  // Find the matching report
  const report = validConditions.find(item => item.report === reportName);

  // If report exists, check if the filterName matches any of the valid filters
  return report ? report.filters.includes(filterName) : false;
}


export const validateInput = (name, event, filterOnChangeHandler, reportName) => {
  const input = event.target;
  const type = DATEFILTER.SEARCH;
  if (input) {
    if (name === "customerCode" && reportName != "Order Loss Report") {
      // For Outlet ID, allow all characters (no restrictions)
      input.value = input?.value?.replace(/[a-zA-Z]/g, ""); // Remove alphabets (both lowercase and uppercase)
      filterOnChangeHandler(name, input?.value, type);
    } else if (name === "productName") {
      filterOnChangeHandler(name, input?.value, type);
    } else {
      input.value = input?.value?.replace(/[^a-zA-Z0-9]/g, "");
      filterOnChangeHandler(name, input?.value, type);
    }
  }
}


export const constructApiEndpoint = (filterValue, searchOptions, searchType) => {
  const options = searchOptions.map((opt) => opt.value).join(',');
  let api = '';
  if (filterValue === "ALL Selected") {
    api = searchOptions.length === 0
      ? `admin/getSalesmanDetails`
      : `admin/filterResult?${searchType}=${options}`;
  } else {
    api = `admin/filterResult?tenantCode=${filterValue}`;
    if (searchOptions.length > 0) {
      api += `&${searchType}=${options}`;
    }
  }
  return api;
};

export const constructExportApiEndpoint = (filterValue, searchOptions, searchType) => {
  const options = searchOptions.map((opt) => opt.value).join(',');
  let api = '';
  if (filterValue === "ALL Selected") {
    api = searchOptions.length === 0
      ? `admin/fileExport`
      : `admin/fileExport?${searchType}=${options}`;
  } else {
    api = `admin/fileExport?tenantCode=${filterValue}`;
    if (searchOptions.length > 0) {
      api += `&${searchType}=${options}`;
    }
  }
  return api;
};

export const getKpiTypeTarget = (newKpiForID) => {
  return newKpiForID ? 'billsProd' : 'prodCall';
};

export const getKpiTypeActual = (newKpiForID) => {
  return newKpiForID ? 'billsProd' : 'prodCalls';
};

export const getKpiTypeStatus = (newKpiForID) => {
  return newKpiForID ? 'billsProd' : 'prod';
};

export const getFilteredData = (data, searchText) => {
  return searchText
    ? data.filter(item =>
        item.salesmanCode && item.salesmanCode.toString().includes(searchText)
      )
    : data;
};

export const getGaEventType = (isDisciplinary, isDistributor) => {
  if (isDistributor) {
    return GA_EVENTS.view_distributor_details;
  } else if (isDisciplinary) {
    return GA_EVENTS.view_disciplinaryKPIs_details;
  } else {
    return GA_EVENTS.view_teams_details;
  }
};

export const getEventType = (isDisciplinary, isDistributor) => {
  if (isDisciplinary) {
    return GA_EVENTS.goback_to_KPI_view;
  } else if (isDistributor) {
    return GA_EVENTS.goback_to_DT_view;
  }
  return GA_EVENTS.goback_to_teamview;
};