export const SUPERUSERLEVELS = [
    {
      levelname: "Super User",
      columnName: "Super User",
      emailId: "",
      active: false,
    },
    {
      levelname: "Area Manager",
      columnName: "Area Manager",
      emailId: "",
      active: true,
    },
    {
      levelname: "Senior/ Territory Manager",
      columnName: "Senior/ Territory Manager",
      emailId: "",
      active: false,
    },
    {
      levelname: "Operations Manager",
      columnName: "Operations Manager",
      emailId: "",
      active: false,
    },
    {
      levelname: "Field Sale Executive",
      columnName: "Field Sale Executive",
      emailId: "",
      active: false,
    },
    {
      levelname: "Sales Officer",
      columnName: "Sales Officer",
      emailId: "",
      active: false,
    },
  ];
  
  export const CDVPLEVELS = [
    {
      levelname: "Regional Manager",
      columnName: "Regional Manager",
      emailId: "",
      active: false,
    },
    {
        levelname: "Area Manager",
        columnName: "Area Manager",
        emailId: "",
        active: true,
      },
      {
        levelname: "Senior/ Territory Manager",
        columnName: "Senior/ Territory Manager",
        emailId: "",
        active: false,
      },
      {
        levelname: "Operations Manager",
        columnName: "Operations Manager",
        emailId: "",
        active: false,
      },
      {
        levelname: "Field Sale Executive",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const BMLEVELS = [
    {
        levelname: "Area Manager",
        columnName: "Area Manager",
        emailId: "",
        active: false,
      },
      {
        levelname: "Senior/ Territory Manager",
        columnName: "Senior/ Territory Manager",
        emailId: "",
        active: true,
      },
      {
        levelname: "Operations Manager",
        columnName: "Operations Manager",
        emailId: "",
        active: false,
      },
      {
        levelname: "Field Sale Executive",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const ASMLEVELS = [
    {
      levelname: "Senior/ Territory Manager",
      columnName: "Senior/ Territory Manager",
      emailId: "",
      active: false,
    },
    {
      levelname: "Operations Manager",
      columnName: "Operations Manager",
      emailId: "",
      active: true,
    },
    {
      levelname: "Field Sale Executive",
      columnName: "Field Sale Executive",
      emailId: "",
      active: false,
    },
    {
      levelname: "Sales Officer",
      columnName: "Sales Officer",
      emailId: "",
      active: false,
    },
  ];
  
  export const SUPLEVELS = [
    {
      levelname: "Operations Manager",
      columnName: "Operations Manager",
      emailId: "",
      active: false,
    },
    {
      levelname: "Field Sale Executive",
      columnName: "Field Sale Executive",
      emailId: "",
      active: true,
    },
    {
      levelname: "Sales Officer",
      columnName: "Sales Officer",
      emailId: "",
      active: false,
    },
  ];
  
  export const DTLEVELS = [
    {
      levelname: "Distributor Details",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
      levelname: "Field Sale Executive",
      columnName: "Field Sale Executive",
      emailId: "",
      active: true,
    },
    {
      levelname: "Sales Officer",
      columnName: "Sales Officer",
      emailId: "",
      active: false,
    },
  ];
  
  export const TLLEVELS = [
    {
      levelname: "Field Sale Executive",
      columnName: "Field Sale Executive",
      emailId: "",
      active: false,
    },
    {
      levelname: "Sales Officer",
      columnName: "Sales Officer",
      emailId: "",
      active: true,
    },
  ];
  
  export const SUPERUSERDISTRIBUTORlEVELS = [
    {
      levelname: "Super User",
      role: "Super User",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const CDVPDISTRIBUTORlEVELS = [
    {
      levelname: "Regional Manager",
      role: "Regional Manager",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const BMDISTRIBUTORlEVELS = [
    {
        levelname: "Area Manager",
      role: "Area Manager",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const ASMDISTRIBUTORlEVELS = [
    {
        levelname: "Senior/ Territory Manager",
        role: "Senior/ Territory Manager",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const SUPDISTRIBUTORlEVELS = [
    {
        levelname: "Operations Manager",
        role: "Operations Manager",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const DTOPERATORLEVELS = [
    {
      levelname: "Field Sale Executive",
      role: "Field Sale Executive",
      columnName: "Distributor Details",
      emailId: "",
      active: false,
    },
    {
        levelname: "Distributor Details",
        role: "LEVEL2",
        columnName: "Distributor Details",
        emailId: "",
        active: true,
      },
      {
        levelname: "Field Sale Executive",
        role: "LEVEL3",
        columnName: "Field Sale Executive",
        emailId: "",
        active: false,
      },
      {
        levelname: "Sales Officer",
        role: "LEVEL4",
        columnName: "Sales Officer",
        emailId: "",
        active: false,
      },
  ];
  
  export const getLevelsBD = (role) => {
    switch (role) {
      case "Super User": {
        return SUPERUSERLEVELS;
      }
      case "Regional Manager": {
        return CDVPLEVELS;
      }
      case "Area Manager": {
        return BMLEVELS;
      }
      case "Senior/ Territory Manager": {
        return ASMLEVELS;
      }
      case "Operations Manager": {
        return SUPLEVELS;
      }
      case "DT Operator": {
        return DTLEVELS;
      }
      case "Field Sale Executive": {
        return TLLEVELS;
      }
      default: {
        return CDVPLEVELS;
      }
    }
  };
  
  export const getDistributorLevelsBD = (role) => {
    switch (role) {
      case "Super User": {
        return SUPERUSERDISTRIBUTORlEVELS;
      }
      case "Regional Manager": {
        return CDVPDISTRIBUTORlEVELS;
      }
      case "Area Manager": {
        return BMDISTRIBUTORlEVELS;
      }
      case "Senior/ Territory Manager": {
        return ASMDISTRIBUTORlEVELS;
      }
      case "Operations Manager": {
        return SUPDISTRIBUTORlEVELS;
      }
      case "Field Sale Executive": {
        return DTOPERATORLEVELS;
      }
      default: {
        return CDVPDISTRIBUTORlEVELS;
      }
    }
  };
  