import React, { useRef, useState, useEffect, useContext } from "react";
import styles from "./Modal.module.scss";
import MicroservicePostFetchUtil from "../../../common/microservice-post-util";
import crossicon from "../../../assets/images/crossIcon.svg";
import blankuploadimage from "../../../assets/images/blankuploadimage.svg";
import successcheck from "../../../assets/images/successcheck.svg";
import uploadingImage from "../../../assets/images/uploading.svg";
import warningicon from "../../../assets/images/warningicon.svg";
import slideicon from "../../../assets/images/slideicon.svg";
import { getCookie } from "../../../utils/utils";
import BrandingLoader from "../../Brandingloader/BrandingLoader";
import {
  EXCEL_FILE_BASE64,
  EXCEL_FILE_SALESMAN_BASE64,
} from "../../../constants/config-constants";
import FileSaver from "file-saver";
import { StoreContext } from "../../ContextStore/StoreContextProvider";
import CustomCrossIcon from "../../../helper/Toast/CustomCrossIcon";
import "../../../helper/Toast/toastify.css";
import "react-toastify/ReactToastify.css";
import { toast } from "react-toastify";
import SuccessIcon from "../../../assets/images/success.svg";

const Modal = ({ title, userType, onClose, setUserListUpdate }) => {
  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [file, setFile] = useState();
  const { userDetails, selectedCountryCode } = useContext(StoreContext);
  console.log(userDetails);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleBrowse = () => {
    fileInputRef.current.click();
  };
  const successToast = () => {
    toast(
      <div className={styles["toast-content"]}>
        <img
          src={SuccessIcon}
          alt="custom-success-icon"
          className={styles["toast-icon"]}
        />
        <div className={styles["toast-message"]}>
          User access data is successfully updated
        </div>
      </div>,
      {
        closeButton: <CustomCrossIcon onClose={toast.dismiss} />,
      }
    );
    setTimeout(() => {
      toast.dismiss();
    }, 5000);
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    console.log(file, "filedata");
    // const formData = new FormData();
    setFile(file);

    if (file && ![".xlsx", ".xml"].includes(file.name.slice(-5))) {
      setFileError(
        "There may be duplicate entries or incoreect in the xls. Please check and upload again."
      );
      setSelectedFile(null);
      setFileSelected(false);
      setUploading(false);
    } else if (file && file.name === selectedFile?.name) {
      setFileError(`File "${file.name}" already exists.`);
      setSelectedFile(null);
      setFileSelected(false);
      setUploading(false);
    } else {
      setSelectedFile(file);
      setFileSelected(true);
      setUploading(true);

      setTimeout(() => {
        setUploading(false);
      }, 2000);

      setFileError(null);
    }
  };

  const handleUpload = () => {
    if (!file) {
      setFileError("Please select a file before uploading.");
      setTimeout(() => {
        setFileError(null);
      }, 1000);
      return;
    }
    setLoader(true);
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    const adminEmail = userDetails && userDetails.email;
    // if (userType === "salesman") {
    formdata.append("updatedBy", adminEmail);
    // }
    let apiEndpoint;
    if (userType === "user") {
      apiEndpoint = "fb/users";
    } else if (userType === "salesman") {
      apiEndpoint = "fb/users?userType=salesman";
    }
    const userList = MicroservicePostFetchUtil.post(
      apiEndpoint,
      { customerToken: getCookie("idToken"), countryCode: selectedCountryCode },
      formdata
    );
    setFileSelected(false);
    userList
      .then(response => {
        if (response.statusCode === 200 && response["response-data"]) {
          setLoader(false);
          successToast();
          setUserListUpdate(true);
          onClose();
        } else if (response.statusCode === 500 && response.errors) {
          setFileError(
            response.errors.shortDescription
              ? response.errors.shortDescription
              : "Upload Failed. Please check file data and upload again."
          );
          setLoader(false);
          setSelectedFile(null);
          setFileSelected(false);
          setUploading(false);
        }
      })
      .catch(error => {
        setFileError("Upload Failed. Please check file data and upload again.");
        console.log(error);
      });
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters;
    console.log("userType:", userType);
    if (userType === "user") {
      byteCharacters = atob(EXCEL_FILE_BASE64);
    } else if (userType === "salesman") {
      byteCharacters = atob(EXCEL_FILE_SALESMAN_BASE64);
    }
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(new Blob(byteArrays), "Template.xlsx");
  };

  return (
    <>
      {loader && <BrandingLoader />}
      <div
        className={`${styles.modal_overlay} ${
          isMobile && styles.mobile_overlay
        }`}
      >
        <div
          className={`${styles.modal} ${isMobile && styles.mobile_modal}`}
          data-testid="modal"
        >
          {!isMobile ? (
            <span className={styles.modal_header}>
              <img
                src={crossicon}
                alt="Close"
                className={styles.close_button}
                onClick={handleClose}
                onKeyDown={() => {}}
              />
            </span>
          ) : (
            <span className={styles.modal_header}>
              <img
                src={slideicon}
                alt="Close"
                className={styles.close_button}
                onClick={handleClose}
                onKeyDown={() => {}}
              />
            </span>
          )}
          <div className={styles.modal_content}>
            <div className={styles.modal_c}>
              <div className={styles.modal_c_header}>
                <h2>{title}</h2>
                <button onClick={handleDownload} className={styles.download}>
                  {" "}
                  Download Template
                </button>
              </div>
            </div>
            <div className={styles.fileupload}>
              <p className={styles.message}>
                Upload filled XLS with new user data
              </p>
              <div className={styles.uploadcontainer}>
                {uploading ? (
                  <div className={styles.uploading}>
                    <img src={uploadingImage} alt="Uploading" />
                    <br />
                    <p>Uploading</p>
                  </div>
                ) : selectedFile ? (
                  <div className={styles.selected_file}>
                    <img src={successcheck} alt="" />
                    <br />
                    <p>Uploaded</p>
                    <br />
                    <p>{selectedFile.name}</p>
                  </div>
                ) : fileError ? (
                  <div className={styles.error_container}>
                    <img src={warningicon} alt="warning" />
                    <p className={styles.caution}>Errors in data</p>
                    <p className={styles.error_message}>{fileError}</p>
                  </div>
                ) : (
                  <div className={styles.logo_container}>
                    <img src={blankuploadimage} alt="" />
                    <div className={styles.textcontainer}>
                      <p className={styles.p1}>
                        <b>Accepted formats:</b> xlsx, xml
                      </p>
                      <p className={styles.p2}>
                        <b>Maximum file size:</b> 25MB
                      </p>
                    </div>
                  </div>
                )}
                {!fileSelected && !uploading && !fileError && (
                  <button
                    className={styles.browse_button}
                    onClick={handleBrowse}
                  >
                    <b>Browse</b>
                  </button>
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx,.xml,.pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  data-testid="file-input"
                />
              </div>
              <div className={styles.button_container}>
                <button onClick={handleClose}>
                  <b>Cancel</b>
                </button>
                <button
                  onClick={handleUpload}
                  className={
                    fileSelected && !uploading ? styles.enable : styles.disable
                  }
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
          {loader && (
            <div className={styles.blurBackground}>
              <BrandingLoader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
