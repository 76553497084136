import configData from '../common/configData.json';
import { NON_SSO } from '../constants/login-constants';

export const getIsVAPTEnabled = (countryCode) => {
    const enableVAPT = configData?.common[countryCode]?.isVAPTEnabled;
    return enableVAPT;
}

export const getIsNonSSOUser = () => {
    const isNonSSOUser = localStorage.getItem("loginType") === NON_SSO;
    return isNonSSOUser;
}

export const getSendVAPTHeaders = (countryCode) => {
    return getIsVAPTEnabled(countryCode);
}